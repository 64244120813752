import { FC, useContext } from "react";
import { FavoriteEventsContext, FeedContext, useRuntimeConfig } from "@finbackoffice/site-core";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Translate from "components/base/translate/Translate";
import PrematchTimeFilterTabs from "components/pages/sport/sidebar/base/prematch-time-filter-tabs/PrematchTimeFilterTabs";
import Loading from "components/base/loading/Loading";
import ActiveLink from "components/base/active-link/ActiveLink";
import Img from "components/base/img/Img";
import { RouteNames } from "utils/constants";
import PrematchSidebarSportsContainer from "./sport/PrematchSidebarSportsContainer";
import styles from "./prematch-sidebar.module.sass";
import PrematchTopLeagues from "./top-leagues/PrematchTopLeagues";

const PrematchSports: FC = () => {
    const { prematchFeed, prematchIsLoading, prematchFeedError } = useContext(FeedContext);
    return (
        <>
            <div className={styles.groupTitle}>
                <Translate tid="sidebar_prematch_sports" />
            </div>
            <PrematchTimeFilterTabs />
            {prematchIsLoading ? (
                <Loading />
            ) : prematchFeedError ? (
                <div className="no-game">{prematchFeedError}</div>
            ) : (
                <PrematchSidebarSportsContainer sports={prematchFeed} />
            )}
        </>
    );
};

const PrematchSidebar: FC = () => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");

    const { favPrematchLeaguesGameCount } = useContext(FavoriteEventsContext);

    return (
        <FadeInAnimation>
            <div className={styles.sportsNav}>
                {!!favPrematchLeaguesGameCount && (
                    <ActiveLink
                        href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${RouteNames.FAVORITES}`}
                        includes={`/${RouteNames.FAVORITES}`}
                        activeClassName={styles.active}
                        className={styles.prematchFavoritesButton}>
                        <>
                            <i className={styles.favoriteStar} />
                            {`Favorite Events (${favPrematchLeaguesGameCount})`}
                            <span className={styles.arrow}>
                                <Img
                                    source={`${ASSETS_URL}/common/desktop/base-icons/arrow-white.svg`}
                                    alt="arrow"
                                    width={10}
                                    height={5}
                                />
                                <Img
                                    source={`${ASSETS_URL}/common/desktop/base-icons/arrow-white.svg`}
                                    alt="arrow"
                                    width={10}
                                    height={5}
                                />
                            </span>
                        </>
                    </ActiveLink>
                )}
                {/* <ActiveLink
                    href={`/${RouteNames.SPORT}/${RouteNames.UPCOMING}`}
                    activeClassName={styles.active}>
                    <>
                        Upcoming Live
                        <span className={styles.arrow}>
                            <Img
                                source={`${ASSETS_URL}/common/desktop/base-icons/arrow-white.svg`}
                                alt="arrow"
                                width={10}
                                height={5}
                            />
                            <Img
                                source={`${ASSETS_URL}/common/desktop/base-icons/arrow-white.svg`}
                                alt="arrow"
                                width={10}
                                height={5}
                            />
                        </span>
                    </>
                </ActiveLink> */}
                <PrematchTopLeagues />
                <PrematchSports />
            </div>
        </FadeInAnimation>
    );
};

export default PrematchSidebar;
